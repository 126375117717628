import React, { useState, useEffect } from 'react';
import {
  Drawer,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Input,
} from '@mui/material';
import {Close,Send} from '@mui/icons-material';

import NoData from '../components/NoData';
import SMS from '../components/SMSCard'

import {ListEndpointSMS,CancelSMS, SendSMS} from '../services/Endpoints';


export default function Popups(props) {
  const [listSMS, setListSMS] = useState([]);
  
  useEffect(() => {
    if(props.open === true){//if component is open, update SMS list
      getListSMS();
    }
  }, [props.open]);//run every time you change props.open 

  /* fetches the list of SMS of an endpoint by its id, and assigns the result in the status "listSMS" */
  const getListSMS = async() => {
    setListSMS(await ListEndpointSMS(props.endpointData.id));
  }
  /* cancels SMS by its id and the SMS list is updated.
  parameters:
    -smsID: id of the sms to be cancelled. */
  const cancelSMS = async(smsID) => {
    await CancelSMS(props.endpointData.id, smsID);
    await getListSMS();
  }
  /* a new SMS is sent and the SMS list is updated
  parameters:
    -messageText: text to be sent in the message. */
  const sendSMS = async() => {
    let messageText = document.getElementById("messageText").value;
    await SendSMS(props.endpointData.id, messageText);
    document.getElementById("messageText").value = ""
    await getListSMS();
  }
  
  return (
    <Drawer
      sx={{ '& .MuiDrawer-paper': { width: 400 } }}
      onClose={props.handleClose}
      open={props.open}
    >
      <DialogTitle sx={{ py: 1 }} id="customized-dialog-title" >
        <Typography sx={{ mr:4, fontSize: '1.25rem' }} display="block" noWrap={true}>
          SMS/ {props.endpointData.name}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          Phone number: <b>+{props.endpointData.msisdn}</b>
        </Typography>
        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 15,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        { listSMS.length > 0 ?//in case there are no notifications show SVG of "no data".
          listSMS.map((value, index) =>{ 
            return( <SMS key={index} dataComparison={[listSMS.length, index+1]} smsDetails={value} cancelSMS={cancelSMS} updateListSMS={getListSMS}/> )
          })
          :
          <NoData/>
        }
      </DialogContent>
      <DialogActions>
        <Input 
          id="messageText"
          sx={{ ml: 2, mb: 2 }}
          placeholder="Message text"
          variant="standard"
          maxRows={ 3 }
          multiline
          fullWidth
          required
        />
        <IconButton 
          onClick={sendSMS}
          color="primary"
        >
          <Send/>
        </IconButton>
      </DialogActions>
    </Drawer>
  );
}
