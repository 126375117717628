import React, { useState, useEffect } from 'react';
import {Container} from '@mui/material';

import DataGrid from '../components/DataGrid';
import UsageAndStatus from '../components/UsageAndStatus';

import {EndpointList,ListEvents} from '../services/Endpoints';


export default function Dashboard(){
  const [listEvents, setListEvents] = useState([]);

  useEffect(() => {
    if(localStorage.length === 0){ window.location.href = '/'; }
    getDataDashboard();
    getListEvents();

    const eventInterval = setInterval(async() => {//while the component is mounted the list of events will be updated every 30s
      await getListEvents();
    }, 30000);
    return () => {
      clearInterval(eventInterval);
    }
  }, []);

  /*fetches the number of SIMs and the number per state, the total amounts will be allocated to the local storage "sims". 
  This data will be displayed in the pie chart.*/
  const getDataDashboard = async() => {
    let newObject = localStorage.getItem("consumedData");
    if(JSON.parse(newObject).data !== undefined){ // if there is already data in the local storage "consumedData", the search and counting of SIMs by status is started/continued
      try {
        let totalData = { activated:0, disabled:0 };

        let endpoints = await EndpointList("all");
        endpoints.map(
          async function createList(element){//list is created with the specific data to be used from the events
            if(element.statusSIM.id === 1) //Active SIM
              { totalData.activated++; }
            else
              { totalData.disabled++; }
          }
        );
        localStorage.setItem("sims", JSON.stringify([
          { label: 'Activated', value: totalData.activated },
          { label: 'Disabled', value: totalData.disabled }
        ]));
      }
      catch(err){ }
    }
  }

  /*fetches for all events of the client's SIM and assigns the list in the state "listEvents" */
  const getListEvents = async() => {
    let events = await ListEvents();
    setListEvents(events);
  }


  return (
    <div className="backPage"  >
      <UsageAndStatus /> {/*current month's data usage information and SIM status pie chart */}

      <Container maxWidth={false} className="containerPage" sx={{ mb: '90px' }}>
        <DataGrid 
          height={560}
          table="allEvents"
          row={listEvents} 
        />
      </Container>

    </div>
  );
}