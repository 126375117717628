import React, {Fragment,useState} from 'react';
import {Container, 
  LinearProgress,
  Tooltip,
  TextField,
  IconButton,
  Button,
  ButtonGroup,
  Typography,
  FormControlLabel,
  Switch,
  Divider
} from '@mui/material';
import {RotateLeft,ChatBubbleOutline,Edit,SaveAs} from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';

import Chart from './BarChart';

export default function Details(props) {
  const [isReadOnly, setIsReadOnly] = useState(true);
  let lastUpdated ="";
  //if there is location in the connectivity data, changes the format of the last update to local 
  if(props.connectivityData.location) { lastUpdated = new Date(props.connectivityData.location.last_updated).toLocaleString(); }

  //Changes the TextField Id "endpointName" and the IconButton to be able to edit the endpoint name.
  const changeName = async()=> {
    if(isReadOnly === false) //if the name can be edited, executes the endpoint update API method. New name = the value of the TextField Id "endpointName
    {
      await props.changeName(document.getElementById("endpointName").value);
    }
    setIsReadOnly(!isReadOnly);
  }

  return (
    <Container maxWidth={false} className="containerPage">
      <LinearProgress sx={props.isLoading === true ?{ display: 'block', mb: 1 } : { display: 'none' }}/>

      {props.simDetails.name && props.connectivityData.status? //displays the components until it loads the endpoint name and connectivity status
      <Grid container spacing={3} px={5} display="flex" justifyContent="center">
        {/*contains TextField with the name of the endpoint and the buttons to reset connectivity and open the sms console*/}
        <Grid xs={12}>
          <ButtonGroup size="large" sx={{ float:'right' }} aria-label="large button group">
            <Tooltip title="Reset connectivity" arrow>
              <span>
              <Button 
                variant="outlined" 
                sx={{ pr: '5px' }} 
                onClick={()=>{props.setOpenReset()}} 
                startIcon={<RotateLeft />} 
                disabled={props.simDetails.statusID === 1 && props.isLoading === false?false:true}
              />
              </span>
            </Tooltip>
            <Tooltip title="Open SMS console" arrow>
              <span>
              <Button 
                variant="outlined" 
                sx={{ pr: '5px' }} 
                onClick={()=>{props.setOpenSMS()}} 
                startIcon={<ChatBubbleOutline />} 
                disabled={props.simDetails.statusID === 1 && props.isLoading === false?false:true}
              />
              </span>
            </Tooltip>
          </ButtonGroup>
          <TextField 
            id="endpointName"
            defaultValue={props.simDetails.name} 
            InputProps={{
              readOnly: isReadOnly,
            }}
            label="Name" 
            variant="standard" 
            sx={{ width: '75%'}} 
          />
          <Tooltip title={isReadOnly === true?"Edit":"Save"} arrow>
            <IconButton color="primary" aria-label="directions" onClick={changeName}>
              {isReadOnly === true?
                <Edit />
                :
                <SaveAs />
              }
            </IconButton>
          </Tooltip>
        </Grid>
        {/*contains SIM and connectivity data*/}
        <Grid xs={12} md={3} >
          {/*SIM data*/}
          <Grid xs={12}> 
            <Divider textAlign="left">DEVICE</Divider>
            <Typography variant="button" display="block" gutterBottom>
              IMEI: {props.simDetails.imei}
            </Typography>
            <Typography variant="button" display="block" gutterBottom>
              ICCID: {props.simDetails.iccid}
            </Typography>
            <Typography variant="button" display="block" gutterBottom>
              Phone Number: +{props.simDetails.msisdn}
            </Typography>
          </Grid>
          
          {/*connectivity and siwtch data for status change*/}
          <Grid xs={12} sx={{ textAlign: 'center', pt: 1 }}>
            <Divider textAlign="left">STATUS</Divider>
            <FormControlLabel 
              control={
                <Switch 
                  size="small" 
                  disabled={props.isLoading}
                  onChange={()=>props.changeStatus()}
                  checked={props.simDetails.statusID === 1?true:false}
                />
              }
              label={props.simDetails.status}
              sx={props.simDetails.status ==="Activated"? {color: 'green'} : props.simDetails.status ==="Issued"? {color: 'grey'}: {color: 'red'}}
            />
            <Typography variant="h6" sx={props.connectivityData.status.description ==="ONLINE"? {color: 'green'} :  props.connectivityData.status.description ==="ATTACHED"? {color: 'orange'}: {color: 'grey'}}> 
              {!props.connectivityData.pdp_context?
                props.connectivityData.status.description 
                :
                props.connectivityData.status.description + " (" + props.connectivityData.pdp_context.rat_type.description + ") "
              }
            </Typography>
            {props.connectivityData.status.description ==="ONLINE" ?
              <Fragment>
              <Typography variant="overline" display="block" >
                {props.connectivityData.location.operator.name + "  " + props.connectivityData.location.country.name}
              </Typography>
              <Typography variant="button"  display="block" sx={{ color: '#084298' }} >
                Connection time: {props.connectivityData.pdp_context.duration}
              </Typography>
              </Fragment>
              :''
            }
            {!props.connectivityData.location? '':
              <Typography variant="caption" >
                Last location update: {lastUpdated}
              </Typography>
            }
          </Grid>
        </Grid>       
        {/*contine the chart with the usage per month*/}
        <Grid xs={12} md={6}>
          <Divider textAlign="left">USAGE</Divider>
          <Chart dataUsage={props.dataUsage} updateGraph={props.updateGraph}/>
        </Grid>
      </Grid>
      : ''
      }
    </Container>
  );
}