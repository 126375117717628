import React, { useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tooltip, IconButton } from '@mui/material';
import {RotateLeft,ChatBubbleOutline} from '@mui/icons-material';

import ResetPopUp from './ResetPopUp';
import SMSPopUp from '../views/SMSPopUp';


export default function EndpointActions(props) {
  const navigate = useNavigate();
  const [openReset, setOpenReset] = useState(false);
  const [openSMS, setOpenSMS] = useState(false);
  
  const handleClose = () => {
    setOpenReset(false); 
    setOpenSMS(false); 
  };

  return (
    <Fragment>
    {/*Reset connectivity*/}
      <Tooltip title="Reset connectivity" arrow>
        <span>
          <IconButton color="primary" onClick={()=>{setOpenReset(true)}} disabled={props.rowData.statusSIM.id === 1?props.isLoading:true}>
            <RotateLeft />
          </IconButton>
        </span>
      </Tooltip>
    {/*Open SMS console*/}
      <Tooltip title="Open SMS console" arrow>
        <span>
          <IconButton color="primary" onClick={()=>{setOpenSMS(true)}} disabled={props.rowData.statusSIM.id === 1?props.isLoading:true}>
            <ChatBubbleOutline />
          </IconButton>
        </span>
      </Tooltip>
    {/*redirect to SIM Details*/}
      <Button variant="outlined" size="small" disabled={props.isLoading}
        onClick={() => {navigate('/simDetails/'+ props.rowData.id, {state:{simID: props.rowData.simID}})}}>
          Details
      </Button>

      <ResetPopUp open={openReset} handleClose={handleClose} updateData={props.updateData} endpointID={props.rowData.id}/> {/*pop up component for reset connectivity*/}
      <SMSPopUp open={openSMS} handleClose={handleClose} endpointData={props.rowData}/> {/*pop up component for SMS console*/}
    </Fragment>
  );
}
