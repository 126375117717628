
import axios from 'axios';

let auth_token = null, tag = null;

//clean localstorage and return to login page
export function Logout(){
   localStorage.clear(); 
   window.location.href = '/';
}

/* returns the list with all the endpoints of the company filtered by SIM status.  
validates the total number of endpoints per tag, in case there are more than a thousand, it repeats the query process.
parameters
 -filterStatus: SIM status by which SIMS will be filtered: all/activated/disabled(issued/suspended) */
export async function EndpointList (filterStatus) {
   let newList=[]; 
   auth_token = localStorage.getItem('auth_token').toString();
   tag = localStorage.getItem('tag').toString();
   if(filterStatus === "all"){filterStatus="";} // if value is "all" no filter is applied
   else{filterStatus= "%2Cstatus%3A"+filterStatus;}
   
   let response = await EndpointPageNumberValidation(tag,filterStatus,1,auth_token,newList);
   newList = response.newList;  //the query brings a maximum of 1000 rows per page
   if(response.totalPages>1){// if there is more than one page it is necessary to execute the API method, as needed.
      for (let i = 2; i <= response.totalPages; i++) {
         let response = await EndpointPageNumberValidation(tag,filterStatus,i,auth_token,newList);
         newList = response.newList;
      }
   }

   return newList;
}
/*returns the updated list of edpoints filtered by the tag which is the company name and SIM status, using the EMnify API
parameters
 -tag: identifier that SIMs have to know which company they belong to 
 -filterStatus: SIM status by which SIMS will be filtered: all/activated/disabled(issued/suspended) 
 -currentPage: page number to consult 
 -auth_token: token for authorizing the API query
 -newList: list with the last SIMs update, in which the result of the new query will be added. */
async function EndpointPageNumberValidation (tag,filterStatus,currentPage,auth_token,newList) {
   let totalPages;
   try {
      await axios.get(`https://cdn.emnify.net/api/v1/endpoint?q=tags%3A${tag+filterStatus}&sort=id&page=${currentPage}`, { headers: { Authorization: auth_token } } )
      .then((response) => { 
         response.data.map(
         function createList(element){ //list is created with the specific data to be used from the endpoint list
           return ( 
            newList.push({
               "id": element.id,
               "name": element.name,
               "statusSIM": element.sim.status,
               "iccid": element.sim.iccid_with_luhn,
               "msisdn":element.sim.msisdn,
               "imei": element.imei_with_luhn,
               "simID": element.sim.id,
            })
           );
         }
      );
      totalPages=response.headers['x-total-pages'];
      });
   } 
   catch (err) { Logout(); } 

   return {newList,totalPages};
}

/* Update the status of a SIM (Activated/Suspended) by ID, using the EMnify API.
it is necessary to perform the same process on the endpoint, for this reason it uses the UpdateEndpoint function.
parameters: 
   - simID: id of the sim to be edited.
   - status: id of the sim status (1=Activated 2=Suspended).
   - endpointID: id of the endpoint status (0=Enabled 1=Disabled).  */
export async function UpdateSim(simID, status, endpointID) {
   let body ={
      "status": {
         "id": status
      }
   }

   try {  
      await axios.patch(`https://cdn.emnify.net/api/v1/sim/${simID}`, body, { headers: { Authorization: auth_token } } ); 
      await UpdateEndpoint(endpointID, ["status", --status]);
   } 
   catch (err) { Logout(); }
}

/* Update name or status(reset) of a endpoint by ID.
parameters: 
   - endpointID: id of the endpoint to be edited.
   - updatedata: array with field and value to update
      status: 0=Enabled 1=Disabled
      name: name of the endpoint  */
export async function UpdateEndpoint(endpointID, updatedata) {
   let body ={}

   if(updatedata[0] === "status"){
      body ={
         "status": {
            "id": updatedata[1]
         }
      }
   }
   else{
      body ={
         "name": updatedata[1]
      }
   }

   try {  await axios.patch(`https://cdn.emnify.net/api/v1/endpoint/${endpointID}`, body, { headers: { Authorization: auth_token } } ); } 
   catch (err) { Logout(); }
}


//DASHBOARD

/* returns the current month's usage, based on the SIM ID using the EMnify API
parameters: 
   - simID: id of the SIM de la que se obtendra el uso de datos  */
export async function CurrentMonthUsage (simID) { 
   let dataUsage={};
   try {
     dataUsage = (await axios.get(`https://cdn.emnify.net/api/v1/sim/${simID}/stats`, { headers: { Authorization: auth_token } } )).data.current_month.data;
   } catch (err) {}

  return dataUsage; 
}

/* returns a list of all events per client(tag). Events of all SIMs, sorted from the most current date to the oldest. */
export async function ListEvents () {
   let events=[];

   let response = await EventsPageNumberValidation(tag,1,events);
   events = response.events;//the query brings a maximum of 500 rows per page
   if(response.totalPages>1){// if there is more than one page it is necessary to execute the API method.Since there may be thousands of events, consult a maximum of 5 times.
      for (let i = 2; (i <= response.totalPages && i < 6); i++) {
         let response = await EventsPageNumberValidation(tag,i,events);
         events = response.events;
      }
   }

   return events;
}
/*returns the updated list of events by client
validates the total number of events per tag, in case there are more than 500, repeats the query process.
parameters
 -tag: identifier that SIMs have to know which company they belong to 
 -currentPage: page number to consult 
 -events: list with the last SIMs update, in which the result of the new query will be added. */
async function EventsPageNumberValidation (tag, currentPage, events) {
   let totalPages;
   try {
       await axios.get(`https://cdn.emnify.net/api/v1/event?sort=-timestamp&q=tags%3A${tag.toLowerCase()}&per_page=500&page=${currentPage}`, { headers: { Authorization: auth_token } } )
       .then((response) => {
           response.data.map(
               async function createList(element){//list is created with the specific data to be used from the events
               let date = new Date(element.timestamp);
               return ( 
                   await events.push({
                   "id": element.id,
                   "simID": element.sim.id,
                   "endpointID": element.endpoint.id,
                   "endpointName": element.endpoint.name,
                   "severity": element.event_severity.description,
                   "date": date.toLocaleString(),
                   "eventSource": element.event_source.description,
                   "eventType": element.event_type.description,
                   "network": element.detail === undefined? '' : Object.keys(element.detail).length === 0? '' : element.detail.name,
                   "country": element.detail === undefined? '' : Object.keys(element.detail).length === 0? '' : 
                              element.detail.country === undefined? '' : element.detail.country.name,
                   })
               );
               }
           );
           totalPages=response.headers['x-total-pages'];
       });
   } 
   catch (err) { Logout(); } 

   return {events,totalPages};
}


//FUNCTION OF DETAILS

/* fetches and returns the connectivity data of an endpoint by its ID, using the EMnify API
parameter:
   -endpointID: id of the endpoint to edit. */
export async function ConnectivityData (endpointID) {
   let connectivityData={};
   try {
      connectivityData = (await axios.get(`https://cdn.emnify.net/api/v1/endpoint/${endpointID}/connectivity`, { headers: { Authorization: auth_token } } )).data;
   } catch (err) { Logout(); }

   return connectivityData; 
}

/* returns a custom object with the details of the SIMm, the search is performed based on the SIM ID, using EMnify API.
parameters:
-simID: id of the sim you want details of. */
export async function SIMDetails (simID) {
   let simDetails={};
   try {
      await axios.get(`https://cdn.emnify.net/api/v1/sim/${simID}`, { headers: { Authorization: auth_token } } )
      .then((response) => { 
         simDetails = {
            "id": response.data.endpoint.id, 
            "statusID": response.data.status.id, 
            "status": response.data.status.description, 
            "name": response.data.endpoint.name, 
            "iccid": response.data.iccid_with_luhn, 
            "imei": response.data.endpoint.imei_with_luhn, 
            "msisdn": response.data.msisdn, 
         }
      })
   } catch (err) { Logout(); }

   return simDetails;
}

/* returns a custom array with a SIM's data and sms usage per day, either current/past month (according to the filtering parameter), using the EMnify API.
parameter:
   -simID: id of the simID to edit.
   -month: parameter that will help to filter the information in current/past month*/
export async function DataUsage (simID, month) {
   let dataUsage=[];
   let dates= await DateFormat(month);
   auth_token = localStorage.getItem('auth_token').toString();
   tag = localStorage.getItem('tag').toString();

   try {
     await axios.get(`https://cdn.emnify.net/api/v1/sim/${simID}/stats/daily?start_date=start_date%3D${dates.start_date}&end_date=end_date%3D${dates.end_date}`, { headers: { Authorization: auth_token } } )
    .then((response) => { 
         response.data.map(
            async function createList(element){ //list is created with the specific data to be used from the graphs
              return ( 
               await dataUsage.unshift({
                  "day": element.date.substr(8,2),
                  "totalMB": element.data.volume > 0? parseFloat(element.data.volume.toFixed(2)) : element.data.volume, 
                  "MBup": element.data.volume_tx > 0? parseFloat(element.data.volume_tx.toFixed(2)) : element.data.volume_tx,
                  "MBdown": element.data.volume_rx > 0? parseFloat(element.data.volume_rx.toFixed(2)) : element.data.volume_rx,
                  "totalSMS": element.sms.volume, 
                  "SMSmt": element.sms.volume_tx,
                  "SMSmo": element.sms.volume_rx,
               })
              );
            }
         );
      });
   } 
   catch (err) { Logout(); }

   return dataUsage; 
}
/* returns object with month start and end date in the required format (YYYYY-MM-DD) for the API query. 
parameter:
   -month: parameter that will help to filter the information in current/past month*/
function DateFormat(month){
   let date = new Date();
   let start_date = (new Date(date.getFullYear(), date.getMonth(), 1)).toISOString();
   let end_date = (new Date(date.getFullYear(), date.getMonth() + 1, 0)).toISOString();
   if(month === "last"){
      start_date = (new Date(date.getFullYear(), date.getMonth() - 1, 1)).toISOString();
      end_date = (new Date(date.getFullYear(), date.getMonth(), 0)).toISOString();
   }
   
   start_date = start_date.slice(0,-14);
   end_date = end_date.slice(0,-14);
   
   return { start_date, end_date };
}

/* returns a list of all the events of an endpoint according to its Id, sorted from the most current date to the oldest. using the EMnify API
validates the total number of events per endpoint in case there are more than a thousand, it repeats the query process.
parameter:
   -endpointID: id of the endpoint. */
export async function ListEndpointEvents (endpointID) {
   let list=[];
   
   let response = await EventPageNumberValidation(endpointID,1,list);
   list = response.newList; //the query brings a maximum of 500 rows per page
   if(response.totalPages>1){// if there is more than one page it is necessary to execute the API method.Since there may be thousands of events, consult a maximum of 5 times.
      for (let i = 2; (i <= response.totalPages && i < 6); i++) {
         let response = await EventPageNumberValidation(endpointID,i,list);
         list = response.newList;
      }
   }

   return list;
}
/*returns the updated list of events by endpoint ID
validates the total number of events per endpoint, in case there are more than 500, repeats the query process.
parameters
 -endpointID: id of the endpoint.
 -currentPage: page number to consult 
 -newList: list with the last SIMs evnets, in which the result of the new query will be added. */
async function EventPageNumberValidation (endpointID, currentPage, newList) {
   let totalPages;
   try {
      await axios.get(`https://cdn.emnify.net/api/v1/endpoint/${endpointID}/event?sort=-timestamp%2Cid&per_page=500&page=${currentPage}`, { headers: { Authorization: auth_token } } )
      .then((response) => { 
         response.data.map(
            async function createList(element){//list is created with the specific data to be used from the events
               let date = new Date(element.timestamp);
              return ( 
               await newList.push({
                  "id": element.id,
                  "severity": element.event_severity.description,
                  "date": date.toLocaleString(),
                  "eventSource": element.event_source.description,
                  "eventType": element.event_type.description,
                  "network": element.detail === undefined? '' : Object.keys(element.detail).length === 0? '' : element.detail.name,
                  "country": element.detail === undefined? '' : Object.keys(element.detail).length === 0? '' : 
                           element.detail.country === undefined? '' : element.detail.country.name,
                  "endpointName": element.endpoint.name,
               })
              );
            }
         );
         totalPages=response.headers['x-total-pages'];
      });
   } 
   catch (err) { Logout(); } 

   return {newList,totalPages};
}

/* returns a list of SMS of an endpoint according to its Id using the EMnify API
parameter:
   -endpointID: id of the endpoint. */
export async function ListEndpointSMS (endpointID) {
   let list=[];
   try {
      list = (await axios.get(`https://cdn.emnify.net/api/v1/endpoint/${endpointID}/sms`, { headers: { Authorization: auth_token } } )).data;
      await list.sort(function (a, b) {
         if (a.id > b.id) {
           return 1;
         }
         if (a.id < b.id) {
           return -1;
         }
         // a must be equal to b
         return 0;
      });
   } catch (err) { Logout(); }
   return list;
}

/* cancel message according to your id, using the EMnify API
parameters:
   -endpointID: id of the edpoint the message belongs to.
   -smsID: id of the sms to cancel */
export async function CancelSMS (endpointID, smsID) {
   try {
      await axios.delete(`https://cdn.emnify.net/api/v1/endpoint/${endpointID}/sms/${smsID}`, { headers: { Authorization: auth_token } } )
   } catch (err) { Logout(); }
}

/* send sms to endpoint according to its id,using the EMnify API
parameters:
-endpointID: id of the endpoint to which the sms will be sent.
-messageText: text of the message to be sent */
export async function SendSMS (endpointID, messageText) {
   let body ={
      "source_address": "1000", 
      "payload": messageText
   }

   try {
      await axios.post(`https://cdn.emnify.net/api/v1/endpoint/${endpointID}/sms`,body, { headers: { Authorization: auth_token } } )
   } catch (err) { Logout(); }
}

   