 /*list of customers with EMnify lines*/
 //status: Active / Suspended
 const Users=[
    {
        "name":"#TesatelGPS!000",
        "password":"654321",
        "status": "Active"
    },
    {
        "name":"#SDR!951",
        "password":"654321",
        "status": "Active"
    },
    {
        "name":"#USER2-SDR",
        "password":"654321",
        "status": "Active"
    },
    {
        "name":"#PABLOA-951",
        "password":"654321",
        "status": "Active"
    },
    {
        "name":"#TCCServicios-gps",
        "password":"654321",
        "status": "Active"
    },
    {
        "name":"#SURELO!147",
        "password":"654321",
        "status": "Active"
    },
    {
        "name":"#CLT!369",
        "password":"654321",
        "status": "Active"
    },
    {
        "name":"#MasterGPS!026",
        "password":"654321",
        "status": "Active"
    },
    {
        "name":"#Alertrack!751",
        "password":"654321",
        "status": "Active"
    },
    {
        "name":"#TRVALIN!751",
        "password":"654321",
        "status": "Active"
    },
    {
        "name":"#EdgarRueda!753",
        "password":"654321",
        "status": "Active"
    },
    {
        "name":"#BLUAGENT!911",
        "password":"654321",
        "status": "Suspended"
    },
    {
        "name":"#KCSM!357",
        "password":"654321",
        "status": "Suspended"
    },
    {
        "name":"#KCSMus!153",
        "password":"654321",
        "status": "Suspended"
    },
    {
        "name":"#PriceLogistics!247",
        "password":"654321",
        "status": "Suspended"
    },
    {
        "name":"#GTS!741",
        "password":"654321",
        "status": "Suspended"
    },
    {
        "name":"#GTSus!751",
        "password":"654321",
        "status": "Suspended"
    },
];

export default Users;