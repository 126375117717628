import React, { useState, useEffect } from 'react';
import {Container} from '@mui/material';

import Alerts from '../components/Alerts';
import DataGrid from '../components/DataGrid';

import {EndpointList, UpdateSim} from '../services/Endpoints';

export default function Endpoint() {
  const [listEndpoints, setListEndpoints] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterStatus, setFilterStatus] = useState("all");
  const [alertDetails, setAlertDetails] = useState({ open:false, text:undefined });

  useEffect(() => {
    if(localStorage.length === 0){ window.location.href = '/'; }
    
    async function componentDidMount(){
      await getListEndpoints(filterStatus);
    }
    componentDidMount();
  }, []);

  /* fetches the list of endpoints by state (all/actived/disabled) and assign the list in the state "listEndpoints".
  Parameters:
   -simStatus: all, 1=Activated 2=disabled */
  const getListEndpoints = async(simStatus) => {
    setListEndpoints(await EndpointList(simStatus));
    setIsLoading(false);
  }
  /* Activated or Suspendedperforms the SIM
  Updates the data on the screen and displays alerts through the function "updateData".
  It is necessary to perform the same process on the endpoint, this requires the id of the endpoint
  Parameters:
    -simID: id of the sim to edit.
    -newStatus: 1=Activated 2=Suspended
    -endpointID: id of the endpoint to edit */
  const changeSimStatus = async(simID, newStatus,endpointID) => {
    await UpdateSim(simID, newStatus,endpointID);
    updateData("Changes saved");
  }

  /*change the status in the select component and run the getListEndpoints function to update the list of endpoints by status
  Parameters:
    -status: new status by which the information was leakedall/actived/disabled(suspended/issues)*/
  const filterChange = async(status) => {
    setFilterStatus(status);
    setIsLoading(true);
    await getListEndpoints(status);
  };
  /* function used in status change, to update the information and display a successful change alert.
  updates the information in the screen, executes the functions; getListEndpoints. And displays alert for 7s.
  parameter:
    -text: text to be displayed in the alert*/
  const updateData = async(text) => {
    setIsLoading(true);
    await getListEndpoints(filterStatus);
    setAlertDetails({ open:true, text:text });
    setTimeout(()=>setAlertDetails({ open:false, text:undefined }), 7000);//oculta la alerta una vez transcurridos 7s
  }

  return (
    <div className="backPage" >
      <Alerts alertDetails={alertDetails} setAlertDetails={setAlertDetails}/>
      
      <Container maxWidth={false} className="containerPage">
        <DataGrid 
          height={650}
          table="endpoints"
          row={listEndpoints} 
          isLoading={isLoading} 
          filterStatus={filterStatus} 
          changeStatus={changeSimStatus} 
          updateData={updateData} 
          filterChange={filterChange}
        />
      </Container>
    </div>
  );
}
