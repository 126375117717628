import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {Box, Toolbar,IconButton, Badge, AppBar,Tooltip, Menu, MenuItem,ListItemIcon,Divider,Stack} from '@mui/material';
import { NotificationsRounded, AccountCircle, ExitToApp, SpaceDashboard, ArrowBack, SimCard,FileDownload} from '@mui/icons-material';
import Logo from '../img/whiteLogo.png';

import {EndpointList,CurrentMonthUsage,Logout} from '../services/Endpoints';
import pdf from '../utils/CC-Documentation.pdf';


export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  useEffect(() => {
    createNotifications();
    setTimeout(()=> Logout(), 2400000); //closing of session after 40minutes
  }, []);
  
  //Notifications are created based on the current month's consumption of each SIM. A notification will be created for each SIM with data usage higher than 8MB.
  //To take advantage of the DB query, also done the data usage calculation for the dashboard.
  const createNotifications = async() => {
    let counter = 0, totalData = { MBup: 0, MBdown: 0, total: 0 },
    consumedData = localStorage.getItem("consumedData");
    let endpoints = await EndpointList("all"); 

    const interval = setInterval(async function(){
      if(counter < endpoints.length){
        try{
          counter++;
          let dataUsage = await CurrentMonthUsage(endpoints[counter-1].simID); 
          if(Object.keys(dataUsage).length > 0 && dataUsage.volume > 0){ 

          //If you have consumptions in the current month, the data usage is calculated for the dashboard and added to the localStorage "consumedData".
          if(JSON.parse(consumedData).data !== undefined){
            totalData.MBup = parseFloat(totalData.MBup) + parseFloat(dataUsage.volume_tx);
            totalData.MBdown = parseFloat(totalData.MBdown) + parseFloat(dataUsage.volume_rx);
            totalData.total = parseFloat(totalData.total) + parseFloat(dataUsage.volume);
           
            localStorage.setItem("consumedData", JSON.stringify(totalData));
          }

          //If the current month's consumption is higher than 8mb, a notification is generated and added to the array.
            if(dataUsage.volume > 8){
              let endpoint = endpoints.find((element) => element.simID.toString() === dataUsage.sim_id);
              setNotifications(current => [...current, 
                {
                  "endpointID": endpoint.id,
                  "simID": endpoint.simID,
                  "name": endpoint.name,
                  "totalMB": dataUsage.volume.slice(0,-4), 
                }
              ]);
            }
          }
        }
        catch (err) { }
      }
      else{ clearInterval(interval); }
    }, 210);
  }

  //opens menu when clicking on the user icon
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  //close menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  //download user manual in PDF format
  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `control-center.pdf`;
    link.href = pdf;
    link.click();
  };

  //returns the menu component
  const renderMenu = (
    <Menu
    anchorEl={anchorEl}
    id="account-menu"
    open={isOpen}
    onClose={handleClose}
    onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem onClick={() => navigate('/dashboard')} >
        <ListItemIcon><SpaceDashboard fontSize="small" /> </ListItemIcon>
        Dashboard
      </MenuItem>
      <MenuItem onClick={() => navigate('/mySims')}>
        <ListItemIcon><SimCard fontSize="small" /> </ListItemIcon>
        My SIMs
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => onDownload()}>
          <ListItemIcon><FileDownload fontSize="small" /> </ListItemIcon>
          Documentation
      </MenuItem>
      <MenuItem onClick={() => Logout()}>
        <ListItemIcon><ExitToApp fontSize="small" /> </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ background: '#193c74' }}>
        <Toolbar>
          <img src={Logo} style={{ maxWidth: '300px', minWidth:'150px' }} alt=''/>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" spacing={0} >
            <Tooltip title={notifications?"Notifications":"Back"} arrow>
              {location.pathname === "/mySims" || location.pathname === "/dashboard"? //The notification icon will be visible if the user is in My Syms or Dashboard, otherwise it will be the back icon.
                <IconButton
                size="large"
                color="inherit"
                onClick={() => {navigate('/notifications', {state:{notifications: notifications}})}} 
                >
                  <Badge badgeContent={notifications.length} max={999} color="error">
                    <NotificationsRounded />
                  </Badge>
                </IconButton>
                :
                <IconButton
                  size="large"
                  color="inherit"
                  onClick={() => navigate(-1)}
                >
                  <ArrowBack />
                </IconButton>
              }
            </Tooltip>
            <Tooltip title={"User:  " + localStorage.getItem('userName')} arrow>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
                onClick={handleClick}
              >
                <AccountCircle />
              </IconButton>
            </Tooltip>
          </Stack>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Box>
  );
}
