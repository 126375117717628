import React, {useState,useEffect} from 'react';
import {useParams,useLocation} from 'react-router-dom';
import {Container} from '@mui/material';

import Alerts from '../components/Alerts';
import DetailsAndUsage from '../components/DetailsAndUsage';
import DataGrid from '../components/DataGrid';
import ResetPopUp from '../components/ResetPopUp';
import SMSPopUp from './SMSPopUp';

import { SIMDetails, ConnectivityData, UpdateSim, UpdateEndpoint, DataUsage, ListEndpointEvents} from '../services/Endpoints';

export default function EndpointDetails() {
  const params = useParams(); const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [alertDetails, setAlertDetails] = useState({ open:false, text:undefined });
  const [simDetails, setSimDetails] = useState({});
  const [connectivityData, setConnectivityData] = useState({});
  const [dataUsage, setDataUsage] = useState({});
  const [listEvents, setListEvents] = useState([]);
  const [openReset, setOpenReset] = useState(false);
  const [openSMS, setOpenSMS] = useState(false);

  const handleClose = () => {
    setOpenReset(false); 
    setOpenSMS(false); 
  };
  
  useEffect(() => {
    if(localStorage.length === 0){ window.location.href = '/'; }
    
    async function componentDidMount(){
      await getDataUsage("current");
      await getConnectivityData();
      await getSIMDetails();
      await getListEvents();
    }
    componentDidMount();
    const interval = setInterval(async() => {//while the component is mounted the list of events and connectivity data will be updated every 30s
      await getConnectivityData();
      await getListEvents();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  /* fetches the sim details by its id
  and assigns it to the "simDetails" state. */
  const getSIMDetails = async() => {
    setSimDetails(await SIMDetails(state?.simID));
  }
  /* fetches the connectivity data according to the endpoint's id and assigns it to the state "connectivityData" */
  const getConnectivityData = async() => {
    setConnectivityData(await ConnectivityData(params.id));
    setTimeout(()=>setIsLoading(false), 3000);
  }
/* fetches the data and sms usage per day of the current/past month and the information is assigned to the state "dataUsage".
The information will be displayed in the bar chart.
parameter:
   -month: parameter that will help to filter the information in current/past month*/
  const getDataUsage = async(month) => {
    let data = await DataUsage(state?.simID,month)
    let totals = data.shift(); //removes the first element of the array, which contains the total usage in the month
    setDataUsage({totals: totals, data:data});
  } 
  /*fetches the list of events of an endpoint by its id and assigns it to the state "listEvents"*/
  const getListEvents = async()=> {
    setListEvents(await ListEndpointEvents(params.id));
  }
  /*edits the name of the endpoint by its id. 
  Updates the data on the screen and displays alerts through the function "updateData".
  parameters:
    -newName: the new name to be assigned to the endpoint. */ 
  const changeName = async(newName) => {
    await UpdateEndpoint(params.id, ["name", newName]);
    updateData("Changes saved");
  }
  /*change the SIM status (active=1,suspended=2) and using its ID.
  Updates the data on the screen and displays alerts through the function "updateData". 
  It is necessary to perform the same process on the endpoint, this requires the id of the endpoint */
  const changeSimStatus = async() => {
    let newStatus=0;
    if(simDetails.statusID === 1) newStatus = 2; //SIM is active, changes to suspended
    else newStatus = 1;
    await UpdateSim(state?.simID, newStatus, params.id);
    updateData("Changes saved");
  }

  /* function used in SIM name and status change, to update the information and display a successful change alert.
  updates the information in the screen, executes the functions; getConnectivityData, getListEvents, getSIMDetails. And displays alert for 7s.
  parameter:
    -text: text to be displayed in the alert*/
  const updateData = async(text) => {
    setIsLoading(true);
    await getConnectivityData();
    await getListEvents();
    await getSIMDetails();
    
    setAlertDetails({ open:true, text:text });
    setTimeout(()=>setAlertDetails({ open:false, text:undefined }), 7000); //oculta la alerta una vez transcurridos 7s
  }

  return (
    <div className="backPage" >
      <Alerts alertDetails={alertDetails} setAlertDetails={setAlertDetails}/>
      
      <DetailsAndUsage 
        //Edpoint Details
        isLoading={isLoading}
        simDetails={simDetails} 
        connectivityData={connectivityData} 
        updateData={updateData} 
        changeStatus={changeSimStatus}
        changeName={changeName}
        //Graph
        dataUsage={dataUsage}
        updateGraph={getDataUsage}
        //SMS & Reset
        setOpenSMS={()=>setOpenSMS(true)} 
        setOpenReset={()=>setOpenReset(true)}
      />

      <Container maxWidth={false} className="containerPage" sx={{ mb: '90px' }}>
        <DataGrid 
          height={560}
          table="events"
          row={listEvents} 
        />
      </Container>
      
      <ResetPopUp open={openReset} handleClose={handleClose} updateData={updateData} endpointID={simDetails.id}/>
      <SMSPopUp open={openSMS} handleClose={handleClose} endpointData={simDetails}/>
  </div>
  );
}
