import React, { Fragment,useEffect,useRef } from 'react';
import {
  Button,
  Stack,
  Typography,
  CardContent,
  CircularProgress 
} from '@mui/material';


export default function Card(props) {
  let messageDate = new Date(props.smsDetails.delivery_date);
  const smsEndRef = useRef(null);

  useEffect(() => {
    //smsEndRef.current?.scrollIntoView();
  // if all sms are already loaded, the list will be updated to see if there are new or changed statuses (every 5s).
   if(props.dataComparison[0] === props.dataComparison[1]){
      const interval = setInterval(async() => {
        await props.updateListSMS();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [props.smsDetails]);

  //components for messages in process of sending, CircularProgress and Button to cancel sms 
  const renderUnsentSMS = (
    <Fragment>
      <CircularProgress color="inherit" size={10} sx={{ mr:1 }}/>
        Waiting for delivery
      {props.smsDetails.status.description === "BUFFERED"?
      <Button variant="outlined" size='small' sx={{ float:'right' }} onClick={()=> props.cancelSMS(props.smsDetails.id)}>Cancel</Button>
      :''
      }
    </Fragment>
  );
  
  return (
    <Stack className="sms" 
      sx={props.smsDetails.sms_type.description === "MT"?
        props.smsDetails.status.description === "DELIVERED"? { background: '#a0c6ec' } :
        props.smsDetails.status.description === "FAILED" || props.smsDetails.status.description === "EXPIRED" || props.smsDetails.status.description === "CANCELED"?
        { background: '#e17171' }:{ background: '#a0c6ecbf'}
        :{float: 'left'}
      }
    >
        <CardContent>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={{ fontSize: '0.70rem', fontWeight: 'bold', width: 1/2}} color="text.secondary" >
              { props.smsDetails.sms_type.description === "MT"? // Mobile Originated (MO) SMS enviados, Mobile Terminated (MT) SMS recibidos
                ("From: " + props.smsDetails.source_address)
                :
                ("To: +" + props.smsDetails.msisdn)
              }
            </Typography>
            <Typography sx={{ fontSize: '0.70rem', fontWeight: 'bold', width: 1/2, textAlign: 'right' }} color="text.secondary" >
              {messageDate.toLocaleString()}
            </Typography>
          </div>
          <Typography sx={{ fontSize: '1.00rem' }} gutterBottom>
            <b>{props.smsDetails.payload}</b>
          </Typography>
          {props.smsDetails.sms_type.description === "MT"?
            <Typography sx={{ fontSize: '0.60rem', fontWeight: 'bold' }} color="text.secondary">
              {props.smsDetails.status.description === "DELIVERED" || props.smsDetails.status.description === "EXPIRED" || 
              props.smsDetails.status.description === "CANCELED" || props.smsDetails.status.description === "FAILED" ?
                props.smsDetails.status.description : renderUnsentSMS
              }
            </Typography> 
          :''
          }
        </CardContent>
        
        <div ref={smsEndRef} />
    </Stack>
  );
}
