import axios from 'axios';
let applicationToken ="eyJhbGciOiJIUzM4NCJ9.eyJhdWQiOiJcL2FwaVwvdjFcL2FwcGxpY2F0aW9uX3Rva2VuIiwic3ViIjoic29wb3J0ZUB0ZXNhdGVsZ3BzLmNvbSIsImVzYy5hcHBzZWNyZXQiOiI1M2UyY2UyNi04ODc5LTQwNTctODRkYi1mYmY5NjFlOWUzM2QiLCJlc2MuYXBwIjoxMTcxOCwiZXNjLnVzZXIiOjIwMTU3NywiZXNjLm9yZyI6NjcwOCwiZXNjLm9yZ05hbWUiOiJURVNBVEVMIiwiaXNzIjoic3BjLWZyb250ZW5kMTAxQHNwYy1mcm9udGVuZCIsImlhdCI6MTY5NzIyMTU2Mn0.d7sv7XiOT-v4qxSuOshIaPd4RSe5pvlD9ggD838tMcT67drLWvKlz5A404a_7NjC";

/*Assigns the token and tag (which will leak user information) to the local storage and redirects to the control panel page. 
In case of error, the error is returned legend "Connection failed, please try again later."
parameter:
   -userName: user name which will be used as a filter in the API query*/
export async function LoginApi(userName) { 
    let body ={ "application_token" : applicationToken }
    try {
        const response = await axios.post(`https://cdn.emnify.net/api/v1/authenticate`, body);
        localStorage.setItem('auth_token', "Bearer " + response.data.auth_token);
        localStorage.setItem('tag', userName.slice(1, -4));
        localStorage.setItem('userName', userName);

        localStorage.setItem('consumedData', JSON.stringify({ data: null, MBup: 0, MBdown: 0, total: 0 }));
        localStorage.setItem('connectivity', JSON.stringify([
            { label: 'Attached', value: 0, color: '#FFBB28' },
            { label: 'Online', value: 0, color: '#00C49F' },
            { label: 'Offline', value: 0, color: '#c1c1c1' }
          ]));
        localStorage.setItem('sims', JSON.stringify([
            { label: 'Activated', value: 0 },
            { label: 'Disabled', value: 0 }
        ]));

        window.location.href = '/dashboard';
    } 
    catch (err) { return "Connection failed, please try again later."; }
}