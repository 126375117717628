import React, { useState, useEffect } from 'react';
import { useLocation,useNavigate } from "react-router-dom";
import {Alert,Stack,Container,LinearProgress} from '@mui/material';

import NoData from '../components/NoData';
let notifications =[];

export default function Notifications(){
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if(localStorage.length === 0){ window.location.href = '/'; }
     /* creates notifications array, based on the "state.notifications" containing the endpoints with current data usage greater than 8MB */
    async function componentDidMount(){
      notifications =[]
      await state?.notifications.forEach( 
      function createList(element){ 
          if(element.totalMB > 10){ //if the MB used is greater than 10, a notification "exceeded" is added.
            notifications.push(
              { 'text':element.name + " has exceeded 10MB.", 'severity': 'error', "endpointID": element.endpointID, "simID": element.simID, }
              );
          }
          else{ // if the MB used is less than 10, a notification "about to exceed" is added.
            notifications.push(
              { 'text':element.name + " is about to exceed 10MB.", 'severity': 'warning', "endpointID": element.endpointID, "simID": element.simID, }
            );
          }
      }
      );
    }
    componentDidMount();
    setIsLoading(false);
  }, [state?.notifications]);//run whenever there are changes in "state.notifications".

  return (
    <div className="backPage">

      <Container maxWidth={false} className="containerPage" sx={{ mb: '90px' }}>
        {isLoading === true? 
          <LinearProgress/>
        : 
          <Stack sx={{ width: 1 }} spacing={ 2 }>
            { state?.notifications.length > 0 ? //in case there are no notifications show SVG of "no data".
            notifications.map((value, index) =>{ 
              return(
                <Alert key={index} 
                severity={value.severity}
                onClick={() => {navigate('/simDetails/'+value.endpointID, {state:{simID: value.simID}})}} 
                >
                  {value.text}
                </Alert>
              )
            })
            :
            <NoData/>
            }
          </Stack>
        }
      </Container>
    </div>
  );
}